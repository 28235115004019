import React from 'react'
import * as productCodes from '@/constants/product-codes'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { scrollToElement, OFFSETS } from '@saatva-bits/pattern-library.utils.position'
import styles from './ProductOverview.module.scss'
import InfoTooltip from '@/components/InfoTooltip'
import RequestSwatches from '@/components/RequestSwatches'

//  Local Link Helpers
const anchorLink = ( text, link ) => {
    return `<a href="${link}" target="_blank" class="t-link">${text}</a>`
}
const SHARED_LINKS = {
    BEDROOM_FURNITURE: anchorLink('bedroom furniture', '/furniture?types=platform+bed+frames%2Cstandard+bed+frames%2Cbenches+ottomans%2Cchairs'),
    STANDARD_BEDFRAME: anchorLink('standard bed frame', '/furniture?styles=standard+bed+frames'),
    PLATFORM_BEDFRAME: anchorLink('platform bed frame', '/furniture?styles=platform+bed+frames'),
    ADJUSTABLE_BASE: anchorLink('adjustable base', '/furniture?types=adjustable+bases'),
    ADJUSTABLE_BASE_UF_KING: anchorLink('Saatva Adjustable Base Plus Upper-Flex King', '/bases/adjustable-base-plus?sku=8105-5/0'),
    ADJUSTABLE_BASE_SPLIT_KING: anchorLink('Split King adjustable base', '/furniture?types=adjustable+bases&sizes=split+king'),
    ADJUSTABLE_BASE_SPLIT_KINGS: anchorLink('Split King & Split Cal King adjustable bases', '/furniture?types=adjustable+bases&sizes=split+king%2Csplit+cal+king'),
    FOUNDATION: anchorLink('foundation', '/furniture/foundation'),
    SYDNEY: anchorLink('Sydney', `/furniture/${productCodes.SYDNEY}`),
    ALL_STORAGE_BED_FRAMES: anchorLink('all storage bed frames', '/furniture?types=bed+frames&styles=storage+bed+frames')
}

//  Local Scroll Button Helpers
const scrollToSectionButton = ( section, text ) => {
    return <button className="t-link" onClick={() => scrollToElement(section, OFFSETS.both)}>{text}</button>
}
const FAQS_SPECS = 'faqs-specs'
const SCROLL_LINKS = {
    [`${productCodes.MEMORY_FOAM_HYBRID}`]: {
        AIR_CRADLE () {
            return (
                <span>Premium {scrollToSectionButton('details', 'AirCradle™ memory foam')} for a body-contouring feel & excellent pressure relief</span>
            )
        },
        LUXE_COOL () {
            return (
                <span>Proprietary {scrollToSectionButton('details', 'LuxeCool™ system')} allows for great airflow & actively draws away body heat to keep you cooler than regular memory foam mattresses</span>
            )
        }
    },
    [`${productCodes.ZENHAVEN}`]: {
        ZONES () {
            return <span>Ergonomic {scrollToSectionButton('five-zone', '5-zone natural latex foam')} layer with vented airflow channels offers button buoyant feel, superior pressure relief, superb breathability, and enhanced lumbar support</span>
        },
        DUAL_SIDED () {
            return <span>Flippable, {scrollToSectionButton('dual-sided-comfort', 'dual-sided design')} allows you to choose the firmness level that suits you</span>
        },
    },
    [`${FAQS_SPECS}`]: {
        SMETA (ScrollButton) {
            return <span>Certified by <ScrollButton copy='SMETA (Sedex Members Ethical Trade Audit)' accordionContent='accordion-content-smeta' />, the world's leading social audit, which assesses factory standards of labor, health & safety, environmental, and business ethics</span>
        },
        GOODWEAVE (ScrollButton) {
            return <span><ScrollButton copy='GoodWeave Certified' accordionContent='accordion-content-goodweave' />, ensuring that each rug is ethically crafted with no child labor, forced labor, or bonded labor used</span>
        },
        OEKO (ScrollButton) {
            return <span><ScrollButton copy='OEKO-TEX® Standard 100 certified' accordionContent='accordion-content-what-is-oekotex' /> to be free of potentially harmful substances</span>
        },
        SA8000 (ScrollButton) {
            return <span><ScrollButton copy='SA8000 Social Accountability Certified' accordionContent='accordion-content-sa8000' /> to ensure fair and decent business practices for workers and demonstrate adherence to the highest social standards</span>
        },
        CERTIPUR (ScrollButton) {
            return <span><ScrollButton copy='CertiPUR-US®' accordionContent='accordion-content-certipur' /> certified foams</span>
        },
        GOTS (ScrollButton) {
            return <span> <ScrollButton copy='GOTS®' accordionContent='accordion-content-gots' /> (Global Organic Textile Standard) certified organic wool</span>
        },
        GOLS (ScrollButton) {
            return <span><ScrollButton copy='GOLS' accordionContent='accordion-content-gols' /> (Global Organic Latex Standard) certified latex foam</span>
        },
        ECO_INSTITUT (ScrollButton) {
            return <span><ScrollButton copy='eco-INSTITUT®' accordionContent='accordion-content-ecoinstitut' /> certified latex foam</span>
        },
        GREENGUARD_LATEX (ScrollButton) {
            return <span><ScrollButton copy='GREENGUARD® Gold' accordionContent='accordion-content-greenguard' /> certified</span>
        },
        GREENGUARD (ScrollButton) {
            return <span><ScrollButton copy='GREENGUARD® Gold' accordionContent='accordion-content-greenguard' /> certified</span>
        },
        CRADLE (ScrollButton) {
            return <span><ScrollButton copy='Cradle to Cradle' accordionContent='accordion-content-cradle-to-cradle-gold-certified' /> GOLD Certified®</span>
        }
    }
}

const BEDFRAMES_TOOLTIPS = {
    COMPATIBLE_WITH_DUAL_SIDED: (
        <span>
            Compatible with most mattresses
            <InfoTooltip
                className={`${styles.tooltip} ${styles.inlineTooltip}`}
                contentClassName={styles.tooltipContent}
            >
                <p>NOTE: Must add a solid surface over the slats such as plywood, masonite board, or bunkie board, when used with the dual-sided <span class='t-fontWeight--normal'>Zenhaven Natural Latex</span> Mattress.</p>
            </InfoTooltip>
        </span>
    ),
    COMPATIBLE_WITH_HD_FOUNDATION: (
        <span>
            Compatible with most mattresses
            <InfoTooltip
                className={`${styles.tooltip} ${styles.inlineTooltip}`}
                contentClassName={styles.tooltipContent}
            >
                <p><span className='t-fontWeight--normal'>Saatva HD</span> requires pairing with the HD Foundation for adequate support.</p>
            </InfoTooltip>
        </span>
    ),
}

const CHECKMARK_SVG = <SvgSprite spriteID='icon-checkmark' className={`${styles.listSVG} ${styles.prefixedSVG}`} />

// These are wrapped in spans to prevent the flex stylings from changing their spacing
const BED_FRAME_COMPATIBILITY = {
    FOUNDATION_OR_BOX_SPRING: <span>NO foundation or box spring required</span>,
    ADJUSTABLE_BASES: <span>Compatible with <span dangerouslySetInnerHTML={{ __html: anchorLink('Saatva Adjustable Bases', '/furniture?types=adjustable+bases')}} /></span>,
    ALL_MATTRESSES: <span>Compatible with all mattresses</span>,
}

const ADJUSTABLE_BASE_COMPATIBILITY = {
    BED_FRAMES: (
        <span>
            Compatible with all Saatva bed frames except <a dangerouslySetInnerHTML={{ __html: SHARED_LINKS.SYDNEY }} /> and <span dangerouslySetInnerHTML={{ __html: SHARED_LINKS.ALL_STORAGE_BED_FRAMES }} />
            <InfoTooltip
                className={`${styles.tooltip} ${styles.inlineTooltip}`}
                contentClassName={styles.tooltipContent}
            >
                <p>To pair your Saatva adjustable base with a non-Saatva bed frame, contact the manufacturer to make sure it's compatible with an adjustable base.</p>
            </InfoTooltip>
        </span>
    ),
    MATTRESSES: (
        <span>
            Compatible with all Saatva mattresses except Saatva Classic 14.5” height, Saatva HD, Saatva Youth, and Crib Mattress
            <InfoTooltip
                className={`${styles.tooltip} ${styles.inlineTooltip}`}
                contentClassName={styles.tooltipContent}
            >
                <p>To pair your Saatva adjustable base with a non-Saatva mattress, contact the manufacturer to check if it's compatible with an adjustable base.</p>
            </InfoTooltip>
        </span>
    )
}

const SHARED_CONTENT = {
    RUG_CARE: {
        heading: 'Care instructions',
        content: [
            {
                type: 'list',
                body: [
                    'Blot spills away immediately and clean with mild soap and cold water',
                    'Blot dry with a clean, absorbent towel',
                    'Avoid prolonged exposure to moisture',
                    'Shedding is normal; vacuum regularly with the beater bar off, going with the grain of the pile, and shedding will reduce over time'
                ]
            }
        ]
    },
    BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED: {
        heading: 'Frame Compatibility',
        content: [
            {
                type: 'prefixed-heading',
                customSvg: CHECKMARK_SVG,
                body: [
                    {
                        body: BED_FRAME_COMPATIBILITY.FOUNDATION_OR_BOX_SPRING,
                    },
                    {
                        body: BED_FRAME_COMPATIBILITY.ADJUSTABLE_BASES,
                    },
                    {
                        body: BEDFRAMES_TOOLTIPS.COMPATIBLE_WITH_DUAL_SIDED,
                    },
                ],
            },
        ],
    },
    BED_FRAME_COMPATIBLE_FOUNDATION_HEIGHT_LIMIT: {
        heading: 'Frame Compatibility',
        content: [
            {
                type: 'prefixed-heading',
                customSvg: CHECKMARK_SVG,
                body: [
                    {
                        body: BED_FRAME_COMPATIBILITY.ADJUSTABLE_BASES,
                    },
                    {
                        body: BEDFRAMES_TOOLTIPS.COMPATIBLE_WITH_HD_FOUNDATION,
                    },
                ],
            },
        ],
        disclaimer: [
            `* Requires a ${anchorLink('foundation', '/furniture/foundation')} or box spring that is no higher than 5\"`,
        ],
    },
    BED_FRAME_INCOMPATIBLE_WITH_ADJUSTABLE_BASES: {
        heading: 'Frame Compatibility',
        content: [
            {
                type: 'prefixed-heading',
                customSvg: CHECKMARK_SVG,
                body: [
                    {
                        body: BED_FRAME_COMPATIBILITY.FOUNDATION_OR_BOX_SPRING,
                    },
                    {
                        body: BED_FRAME_COMPATIBILITY.ALL_MATTRESSES,
                    },
                ],
            },
        ],
        disclaimer: [
            '* Not compatible with adjustable bases',
        ],
    }
}

const config = {

    // MATTRESSES

    [productCodes.SAATVA_CLASSIC]: [
        {
            heading: 'Features & Benefits',
            combined: true,
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        'Luxurious 3” Euro pillow top is available in three comfort levels for cushioned comfort and instant pressure relief',
                        'Durable and responsive dual-coil design, made up of over 1,000 coils (in full size and up), contours to your curves to relieve pressure points, allows great airflow, and prevents sagging over time',
                        'Patented Lumbar Zone® Technology supports healthy spinal alignment to help relieve back pain',
                        'Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep',
                        'Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew'
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        'Congress of Chiropractic State Association Seal of Approval',
                        'Endorsed by the American Chiropractic Association',
                        SCROLL_LINKS[FAQS_SPECS].GREENGUARD
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Mattress foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3” wide and spaced less than 4” apart`
                        },
                        {
                            title: `Adjustable base*`,
                            body: `(11.5” height only) Place on an ${SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort`
                        }
                    ]
                }
            ],
            disclaimer: [
                `* The Saatva Classic 11.5" Split King & Split Cal King pair exclusively with our ${SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KINGS}`
            ]
        },
        {
            heading: 'Firmness',
            content: [
                {
                    type: 'heading',
                    body: [
                        {
                            body: <p>{scrollToSectionButton('details', 'Available in three comfort levels:')}</p>
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Plush Soft',
                            items: [
                                'Contouring, body-hugging feel',
                                'Best suited for side sleepers and lighter weight sleepers'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Luxury Firm',
                            items: [
                                'Our most popular comfort level',
                                'Ideal for couples with different firmness preferences and sleep styles',
                                'Best suited for back sleepers, but also great for stomach, and side sleepers'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Firm',
                            items: [
                                `"Floating on top" feel`,
                                'Best suited for stomach sleepers, but also great for back sleepers who prefer a very firm mattress'
                            ]
                        }
                    ]

                }
            ]
        }
    ],
    [productCodes.MEMORY_FOAM_HYBRID]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[productCodes.MEMORY_FOAM_HYBRID].AIR_CRADLE,
                        'Durable individually wrapped coils for a responsive feel & greater ease of movement',
                        SCROLL_LINKS[productCodes.MEMORY_FOAM_HYBRID].LUXE_COOL,
                        'Patented Lumbar Zone® Quilting & gel-infused memory foam help relieve back pain by keeping your spine in healthy alignment',
                        'Exclusive Guardin™ botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew'
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Mattress foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3” wide and spaced less than 4” apart`
                        },
                        {
                            title: `Adjustable base*`,
                            body:
                                `Place on an ${SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort`
                        }
                    ]
                }
            ],
            disclaimer: [
                `* Memory Foam Hybrid Split King pairs best with our ${SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}`
            ]
        },
        {
            heading: 'Firmness',
            combined: true,
            content: [
                {
                    type: 'list-typed',
                    body: [
                        {
                            title: 'Medium-Firm ',
                            items: [
                                'Ideal for couples with different firmness preferences',
                                'Best suited for side sleepers, but also great for back and combination sleepers'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        'Congress of Chiropractic State Association Seal of Approval',
                        'Endorsed by the American Chiropractic Association'
                    ]
                }
            ]
        }
    ],
    [productCodes.SAATVA_RX]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        `Exclusively designed to give maximum support and pressure relief that can help alleviate discomfort associated with <span class="t-fontWeight--normal">serious and chronic back & joint conditions like sciatica, arthritis, herniated disc, and scoliosis</span>`,
                        'Unique Therapeutic Support Core™ automatically adjusts to your body’s curves and movements, virtually eliminating pressure points for better circulation and more restorative sleep',
                        'Patented Lumbar Zone® Quilting & gel-infused memory foam help relieve back pain by keeping your spine in healthy alignment',
                        'Treated with our exclusive Guardin™ botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew'
                    ]
                }
            ],
            note: [
                `For normal aches & pains, we recommend our best-selling ${anchorLink('Saatva Classic', '/mattresses/saatva-classic')}, available in 3 comfort options`,
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Mattress foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3” wide and spaced less than 4” apart`
                        },
                        {
                            title: `Adjustable base*`,
                            body:
                                `Place on an ${SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort`

                        }
                    ]
                }
            ],
            disclaimer: [
                `* Saatva Rx Split King pairs exclusively with our ${SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}`
            ]
        },
        {
            heading: 'Firmness',
            combined: true,
            content: [
                {
                    type: 'list-typed',
                    body: [
                        {
                            title: 'Supportive Plush',
                            items: [
                                'Plush sleep surface instantly relieves pressure points',
                                'Supportive core keeps the spine in perfect, healthy alignment',
                                'Best suited for side sleepers, but also great for back and combination sleepers who suffer from chronic back & joint conditions'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        'Congress of Chiropractic State Association Seal of Approval',
                        'Endorsed by the American Chiropractic Association'
                    ]
                }
            ]
        }
    ],
    [productCodes.LOOM_AND_LEAF]: [
        {
            heading: 'Features & Benefits',
            combined: true,
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        `Excellent pressure-point relief with CertiPUR-US® certified memory foams that hug your body's curves`,
                        `Sleep cooler than traditional memory foam mattresses with its gel-infused memory foam and breathable cover made with organic cotton`,
                        'Excellent motion isolation for couples',
                        'Two firmness options available to support all sleepers',
                        'Lumbar Zone® Quilting & gel-infused memory foam lumbar crown provide enhanced lower back support',
                        'Treated with our exclusive Guardin® botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew.'
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        'Congress of Chiropractic State Association Seal of Approval',
                        'Endorsed by the American Chiropractic Association'
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Foundations or box springs`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3” wide and spaced less than 4” apart`
                        },
                        {
                            title: `Adjustable base*`,
                            body: `Place on an ${SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort`
                        }
                    ]
                }
            ],
            disclaimer: [
                '* Loom & Leaf Split King & Split Cal King pair exclusively with our Split King & Split Cal King adjustable bases'
            ]
        },
        {
            heading: 'Firmness',
            content: [
                {
                    type: 'heading',
                    body: [
                        {
                            body: `Available in two comfort levels:`
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Relaxed-Firm',
                            items: [
                                'Our most popular comfort level',
                                'Ideal for side sleepers, but also great for back and combination sleepers',
                                'Balance of body-hugging comfort and adequate support'
                            ]
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Firm',
                            items: [
                                'Firmer foams offer less of a body-hugging feel & more responsiveness',
                                'Ideal for stomach sleepers or those who need or prefer a firm mattress',
                                'Balance of body-contouring comfort and firm support'
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    [productCodes.SAATVA_CONTOUR5]: [
        {
            heading: 'Features & Benefits',
            combined: true,
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        `Luxurious body-hugging feel and pressure relief with 3” of ultra-premium, high-density 5lb memory foam`,
                        `Adaptive cooling technology keeps your body at optimal temperature while providing enhanced lumbar support for more restful sleep`,
                        'Excellent motion isolation for couples',
                        'Two firmness options available to support all sleepers',
                        'Treated with our exclusive Guardin® botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew.'
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        'Congress of Chiropractic State Association Seal of Approval',
                        'Endorsed by the American Chiropractic Association'
                    ]
                }
            ],
            disclaimer: [
                '* CertiPUR-US® is a registered trademark of the Alliance for Flexible Polyurethane Foam, Inc.'
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Foundations or box springs`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3” wide and spaced less than 4” apart`
                        },
                        {
                            title: `Adjustable base*`,
                            body: `Place on an ${SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort`
                        }
                    ]
                }
            ],
            disclaimer: [
                `* Contour5 Split King pairs exclusively with our ${SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}`
            ]
        },
        {
            heading: 'Firmness',
            content: [
                {
                    type: 'heading',
                    body: [
                        {
                            body: `Available in two comfort levels:`
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Medium',
                            items: [
                                'Ideal for side sleepers, but also great for back and combination sleepers',
                                'Balance of body-hugging comfort and adequate support',
                                'Ideal for couples with different firmness preferences and sleep styles'
                            ]
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Firm',
                            items: [
                                'Firmer foams offer less of a body-hugging feel & more responsiveness',
                                'Ideal for stomach sleepers or those who need or prefer a firm mattress',
                                'Balance of body-contouring comfort and firm support'
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    [productCodes.DOG_BED]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        `Responsive micro-coils for buoyant support, healthy spinal alignment, breathability, and greater ease of movement for pets of all ages`,
                        `Pillow top filled with plush comfort fibers to help reduce pet dander while keeping your pet cool & comfy`,
                        `Coil design prevents it from flattening out over time, a common issue with fiber or foam-filled pet beds`,
                        `Great for pets of all ages, particularly older dogs or those who have mobility issues`,
                        `Removable high-performance fabric cover with gusseted design repels stains and liquids for easy spot cleaning`,
                        `Unique fabric finish repels liquids easily while allowing air to flow freely, keeping the bed cover breathable`,
                        `Non-slip base helps keep pet bed in place`
                    ]
                }
            ]
        },
        {
            heading: 'Size guide',
            content: [
                {
                    combined: true,
                    type: 'list-typed',
                    body: [
                        {
                            title: `Small - 18” W x 24” L x 4” H`,
                            items: [
                                'For extra small to small dogs, puppies & cats',
                                'Comfortably supports up to 45 pounds'
                            ]
                        }
                    ]
                },
                {
                    combined: true,
                    type: 'list-typed',
                    body: [
                        {
                            title: `Medium - 25” W x 35” L x 4” H`,
                            items: [
                                'For small to medium dogs',
                                'Comfortably supports up to 90 pounds'
                            ]
                        }
                    ]
                },
                {
                    combined: true,
                    type: 'list-typed',
                    body: [
                        {
                            title: 'Large - 34” W x 44” L x 4” H',
                            items: [
                                `For medium to large dogs or two small or medium “bonded pair” dogs who like to share a bed`,
                                'Comfortably supports up to 120 pounds'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Fabric content & care',
            content: [
                {
                    type: 'list',
                    body: [
                        `<span class="t-fontWeight--normal">Taupe Boucle & Slate Boucle -</span> 98% Polyester, 4% Acrylic`,
                        `<span class="t-fontWeight--normal">Natural Linen -</span> 83% Polyester, 10% Acrylic, and 7% Linen`,
                        `To clean, simply wipe spills promptly with a paper towel or cloth rag`,
                        `<span class="t-fontWeight--normal">For tougher stains & spills:</span><br>Remove the outer cover. Dampen a cloth rag with a mixture of 16 oz. water & 1 Tbsp liquid detergent. Gently blot the soiled area, avoid rubbing. Wipe down the area with a clean damp cloth until all soapy water residue is removed.`
                    ]
                }
            ]
        }
    ],
    [productCodes.BUNK_TRUNDLE_YOUTH]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        `Low-profile 6” height works seamlessly with most bunk beds and trundle beds.`,
                        `Lumbar Zone® Quilting & gel-infused memory foam lumbar crown provide enhanced lower back support.`,
                        `Responsive recycled steel coils encased in foam prevents sagging.`,
                        `Treated with our exclusive Guardin™ botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew.`
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    body: [
                        {
                            body: `Designed to use exclusively on bunk beds & trundle beds that typically require a lower mattress height`
                        }
                    ]
                },
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Bunk beds`,
                            body: `Fits on the top bunk without sitting over the guard rails`
                        },
                        {
                            title: `Trundle beds`,
                            body: `Fits under the main bed of roll-out or pop-up trundle beds`
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Firmness',
            combined: true,
            content: [
                {
                    type: 'list-typed',
                    body: [
                        {
                            title: 'Medium-Firm',
                            items: [
                                'Optimal comfort level for most sleep positions',
                                'Ideal for kids, teens, and even overnight guests'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        'Congress of Chiropractic State Association Seal of Approval'
                    ]
                }
            ]
        }
    ],
    [productCodes.ZENHAVEN]: [
        {
            heading: 'Features & Benefits',
            combined: true,
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[productCodes.ZENHAVEN].ZONES,
                        SCROLL_LINKS[productCodes.ZENHAVEN].DUAL_SIDED,
                        'Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep',
                        'Organic natural latex is naturally very breathable and organic wool helps regulate body temperature for more comfortable sleep',
                        'Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew'
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].GOTS,
                        SCROLL_LINKS[FAQS_SPECS].GOLS,
                        SCROLL_LINKS[FAQS_SPECS].ECO_INSTITUT,
                        SCROLL_LINKS[FAQS_SPECS].GREENGUARD_LATEX,
                        `Congress of Chiropractic State Association Seal of Approval`,
                        'Endorsed by the American Chiropractic Association'
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Mattress foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring`
                        },
                        {
                            title: <React.Fragment>
                                Platform bed frame
                                <InfoTooltip
                                    className='rightSetup__tooltip'
                                    buttonClassName='rightSetup__tooltipButton'
                                    contentClassName='rightSetup__tooltipContent'
                                >
                                    <p>Placing the Zenhaven over slats without a solid surface may void the lifetime warranty.</p>
                                </InfoTooltip>
                            </React.Fragment>,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with a solid surface or on plywood, masonite board, or ${anchorLink('bunkie board', '/furniture/foundation')} placed over the slats to protect the dual-sided mattress`
                        },
                        {
                            title: `Adjustable base*`,
                            body:
                                `Place on an ${SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort`

                        }
                    ]
                }
            ],
            disclaimer: [
                `* Zenhaven Upper-Flex King pairs exclusively with our ${SHARED_LINKS.ADJUSTABLE_BASE_UF_KING}  
                Zenhaven Split King pairs exclusively with our ${SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}`
            ]
        },
        {
            heading: 'Firmness',
            content: [
                {
                    type: 'heading',
                    body: [
                        {
                            body: `Flippable, dual-sided design`
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Luxury Plush',
                            items: [
                                'Best suited for side sleepers, but also great for back & combination sleepers',
                                'Offers more body contouring than the firm side to relieve pressure points on hips and shoulders'
                            ]
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Gentle Firm',
                            items: [
                                '18% firmer than the Luxury Plush side',
                                'Best suited for stomach sleepers, but also great for back sleepers'
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    [productCodes.SOLAIRE]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        '50 precise firmness settings on both sides (in Queen or larger)',
                        'Virtually no motion transfer (great for couples)',
                        'Quiet, streamlined components',
                        'Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep',
                        'Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew'
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Mattress foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3" wide and spaced less than 4" apart`
                        },
                        {
                            title: `Adjustable base*`,
                            body:
                                `Place on an ${SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort`

                        }
                    ]
                }
            ],
            disclaimer: [
                `* The Solaire Upper-Flex pairs exclusively with our ${SHARED_LINKS.ADJUSTABLE_BASE_UF_KING}`,
                `* Solaire Split King & Split Cal King pair exclusively with our ${SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KINGS}`
            ]
        },
        {
            heading: 'Firmness',
            combined: true,
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: '50 firmness settings',
                            items: [
                                'Ideal for couples with different firmness preferences',
                                'Ideal for all sleep positions'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        SCROLL_LINKS[FAQS_SPECS].ECO_INSTITUT,
                        `Congress of Chiropractic State Association Seal of Approval`,
                        'Endorsed by the American Chiropractic Association'
                    ]
                }
            ]
        }
    ],
    [productCodes.SAATVA_HD]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        '3" Euro pillow top with a hand-tufted finish offers plush, cushioned comfort for every body type',
                        'Engineered to comfortably support sleepers up to 500 lbs with a high-durability coil base',
                        'Patented Lumbar Zone® Technology supports healthy spinal alignment to help relieve back pain',
                        'Sleeps cooler with natural latex, an open coil base, and a breathable cover made with organic cotton ',
                        'Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew'
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Saatva HD Foundation (sold separately) *`,
                            body: `Place on the durable HD foundation on its own or with a standard bed frame that requires a foundation`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with the required HD foundation`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3” wide and spaced less than 4” apart`
                        }
                    ]
                }
            ],
            disclaimer: [
                '* Saatva HD is NOT compatible with regular foundations or box springs. Our HD foundation was designed to pair exclusively with the Saatva HD.',
                '* Saatva HD is NOT compatible with any adjustable bases due to its thickness and heavy duty steel coils.'
            ]
        },
        {
            heading: 'Firmness',
            combined: true,
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Medium-Firm*',
                            items: [
                                'Offers an optimal balance of cushioned comfort and extra-durable support',
                                'Most comparable to our best-selling Saatva Classic Luxury Firm in comfort level',
                                'Best suited for back sleepers, but also great for combination & stomach sleepers'
                            ]
                        }
                    ]
                }
            ],
            disclaimer: [
                '* Based on heavier sleepers weighing 300 to 500 pounds. Sleepers under 300 pounds might find Saatva HD to have a firmer feel.'
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        SCROLL_LINKS[FAQS_SPECS].ECO_INSTITUT,
                        'Congress of Chiropractic State Association Seal of Approval',
                        'Endorsed by the American Chiropractic Association'
                    ]
                }
            ]
        }
    ],
    [productCodes.SAATVA_LATEX_HYBRID]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Hand-tufted for durability with a cover made with naturally breathable organic cotton',
                        'Ergonomic 5-zone organic natural latex foam layer with vented airflow channels for a buoyant feel, superior pressure relief, breathability, and enhanced lumbar support',
                        'Individually wrapped coils contour and respond to your body’s curves and movements',
                        'Sleeps cooler with organic natural latex, cover made with organic cotton, and organic wool',
                        'Exclusive Guardin™ botanical antimicrobial treatment inhibits the growth of bacteria, mold, and mildew'
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Mattress foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with a required foundation or box spring`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with either a solid surface or slatted platform with slats that are at least 3” wide and spaced less than 4” apart`
                        },
                        {
                            title: `Adjustable base*`,
                            body: `Place on an ${SHARED_LINKS.ADJUSTABLE_BASE} to elevate head and feet for the most customized comfort`
                        }
                    ]
                }
            ],
            disclaimer: [
                `* Saatva Latex Hybrid Split King pairs exclusively with our ${SHARED_LINKS.ADJUSTABLE_BASE_SPLIT_KING}`
            ]
        },
        {
            heading: 'Firmness',
            combined: true,
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Medium-Firm',
                            items: [
                                'Ideal for couples with different firmness preferences and sleep styles',
                                'Offers an optimal balance of responsive support and a buoyant feel',
                                'Ideal for all sleep positions and best suited for back sleepers'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Certifications & Awards',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].GOTS,
                        SCROLL_LINKS[FAQS_SPECS].GOLS,
                        SCROLL_LINKS[FAQS_SPECS].ECO_INSTITUT,
                        SCROLL_LINKS[FAQS_SPECS].GREENGUARD_LATEX,
                        `Congress of Chiropractic State Association Seal of Approval`,
                        'Endorsed by the American Chiropractic Association'
                    ]
                }
            ]
        }
    ],
    [productCodes.SAATVA_YOUTH]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Dual-sided to adapt to your growing child’s needs from ages 3-12',
                        'Lumbar Zone® Quilting for enhanced lower back support',
                        'Enhanced support at the center third on both sides of the mattress for proper spinal alignment at every stage of growth',
                        'Dual-sided coil unit is strong yet flexible to conform to growing bodies',
                        'Cover made with naturally hypoallergenic and breathable organic cotton for cooler sleep',
                        'Treated with our exclusive Guardin™ botanical antimicrobial treatment to inhibit the growth of bacteria, mold, and mildew'
                    ]
                }
            ]
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Mattress foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.FOUNDATION} on its own or with the optional universal frame to raise the foundation off the floor`
                        },
                        {
                            title: `Standard bed frame + foundation or box spring`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} with the required foundation or box spring`
                        },
                        {
                            title: `Platform bed frame`,
                            body: `Place on a ${SHARED_LINKS.PLATFORM_BEDFRAME} with a solid surface or on plywood, masonite board, or bunkie board placed over the slats to protect the dual-sided mattress`
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Firmness',
            combined: true,
            content: [
                {
                    type: 'heading',
                    body: [
                        {
                            body: `Flippable, dual-sided design`
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Age 3-7 Side: Plush Soft',
                            items: [
                                'Designed for younger kids who need flexibility with proper spinal support'
                            ]
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Age 8-12 Side: Medium-Firm',
                            items: [
                                'Firmer support that maintains proper spinal alignment in older kids'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Certifications',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR,
                        'Congress of Chiropractic State Association Seal of Approval'
                    ]
                }
            ]
        }
    ],
    [productCodes.CRIB_MATTRESS]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Dual-sided to adapt to your growing baby through their toddler years',
                        'Reinforced with double edge support to keep your baby or toddler safely centered on the crib',
                        'Removable and washable cover made with organic cotton and treated with an eco-friendly water-repellent treatment'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            combined: true,
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Standard crib - 28” W x 52” L</span>'
                    ]
                }
            ]
        },
        {
            heading: 'Certifications & Awards',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].GOTS,
                        SCROLL_LINKS[FAQS_SPECS].CRADLE,
                        SCROLL_LINKS[FAQS_SPECS].GREENGUARD
                    ]
                }
            ]
        },
        {
            heading: 'Firmness',
            content: [
                {
                    type: 'heading',
                    body: [
                        {
                            body: `Flippable, dual-sided design`
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    body: [
                        {
                            title: 'Baby Side: Extra Firm',
                            items: [
                                'Designed to give infants extra firm support recommended by physicians to help prevent SIDS and other sleep-related accidents'
                            ]
                        }
                    ]
                },
                {
                    type: 'list-typed',
                    body: [
                        {
                            title: 'Toddler Side: Firm',
                            items: [
                                'Designed to give optimal, responsive support for active toddlers'
                            ]
                        }
                    ]
                }
            ]
        }
    ],

    
    [productCodes.FOUNDATION]: [
        {
            heading: 'Features & Benefits',
            combined: true,
            content: [
                {
                    type: 'list',
                    body: [
                        'Handcrafted from North American spruce, making it more durable than a traditional box spring',
                        'CertiPUR-US® foams, and a cover made with organic cotton for optimal breathability',
                        'Rounded corners create a seamless look with your Saatva mattress'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        'Twin - 38" X 75"',
                        'Twin XL - 38" X 80"',
                        'Full - 54" X 75"',
                        'Queen/Split - 60" X 80"',
                        'King/Split - 76" X 80"*',
                        'Cal King/Split - 72" X 84"*'
                    ]
                }
            ],
            disclaimer: [
                '* King and Cal King foundations come as a split design for ease of delivery and setup. This does not affect the feel or performance of the foundation or bunkie board under your mattress.'
            ]
        },
        {
            heading: 'Certifications',
            combined: true,
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        SCROLL_LINKS[FAQS_SPECS].CERTIPUR
                    ]
                }
            ]
        },
        {
            heading: 'Foundation Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Standard (non platform) bed frame`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} and pair with any mattress for added bed height`
                        },
                        {
                            title: `Universal frame*`,
                            body: `Place on the steel universal frame (sold separately) for a minimal bed setup`
                        }
                    ]
                }
            ]
        },
        {
            heading: 'Bunkie Board Compatibility',
            content: [
                {
                    type: 'heading',
                    customSvg: <SvgSprite spriteID='icon-checkmark' className={styles.listSVG} />,
                    body: [
                        {
                            title: `Standard (non platform) bed frame`,
                            body: `Place on a ${SHARED_LINKS.STANDARD_BEDFRAME} by itself for a lower bed height or over an older box spring that needs added support`
                        },
                        {
                            title: `Slatted platform bed frame`,
                            body: `Place a bunkie board over the slats to create a stronger, solid surface for dual-sided mattresses like our ${anchorLink('Zenhaven', '/mattresses/zenhaven')} or heavier foam mattresses that require added support`
                        },
                        {
                            title: `Universal frame*`,
                            body: `Place on the steel universal frame (sold separately) for a minimal, low-profile bed setup`
                        },
                        {
                            title: `Bunk beds, loft beds & day beds`,
                            body:
                                `Place on bunk, loft, or day beds to add support without adding a lot of height`

                        }
                    ]
                }
            ],
            disclaimer: [
                '* Universal Frame (sold separately) supports and lifts the foundation or bunkie board and mattress 7.5" off the ground. Mattress cannot sit directly on the Universal Frame without a foundation.'
            ]
        }
    ],

    // RUGS

    [productCodes.KANAN_BEDROOM_RUG]: [
        {
            heading: 'Features',
            content: [
                {
                    type: 'list',
                    body: [
                        '85% wool & 15% PET yarn',
                        '1/2” pile height',
                        '100% cotton backing',
                        'Suitable for light to heavy-traffic areas',
                        `Meticulously designed to pair perfectly with Saatva upholstered ${SHARED_LINKS.BEDROOM_FURNITURE}`,
                        'Handcrafted by one of the world’s most prestigious rug makers in Jaipur, India'
                    ]
                }

            ],
            note: [
                `Due to the handcrafted nature of our rugs, slight variations in patterns and colors are inherent to each piece.`
            ]
        },
        {
            heading: 'Sustainably & ethically crafted',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        'Eco-friendly PET yarn made of recycled plastics',
                        'Renewable & sustainable natural wool',
                        SCROLL_LINKS[FAQS_SPECS].SMETA,
                        SCROLL_LINKS[FAQS_SPECS].GOODWEAVE,
                        'GoodWeave label ensures that your purchase helps support programs to educate children and improve conditions for adult workers in the community'
                    ]
                }
            ]
        },
        SHARED_CONTENT.RUG_CARE
    ],
    [productCodes.ADAMAS_BEDROOM_RUG]: [
        {
            heading: 'Features',
            content: [
                {
                    type: 'list',
                    body: [
                        '100% wool',
                        '1/2” pile height',
                        'Double-sided, unbacked construction',
                        'Suitable for light to heavy-traffic areas',
                        `Meticulously designed to pair perfectly with Saatva upholstered ${SHARED_LINKS.BEDROOM_FURNITURE}`,
                        'Handcrafted by one of the world’s most prestigious rug makers in Jaipur, India'
                    ]
                }

            ],
            note: [
                `Due to the handcrafted nature of our rugs, slight variations in patterns and colors are inherent to each piece.`
            ]
        },
        {
            heading: 'Sustainably & ethically crafted',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        'Renewable & sustainable natural wool',
                        SCROLL_LINKS[FAQS_SPECS].GOODWEAVE,
                        'GoodWeave label ensures that your purchase helps support programs to educate children and improve conditions for adult workers in the community'
                    ]
                }
            ]
        },
        SHARED_CONTENT.RUG_CARE
    ],
    [productCodes.CIRRUS_BEDROOM_RUG]: [
        {
            heading: 'Features',
            content: [
                {
                    type: 'list',
                    body: [
                        '100% recycled PET yarn',
                        '1/2” pile height',
                        'Double-sided, unbacked construction',
                        'Suitable for light to heavy-traffic areas',
                        `Meticulously designed to pair perfectly with Saatva upholstered ${SHARED_LINKS.BEDROOM_FURNITURE}`,
                        'Handcrafted by one of the world’s most prestigious rug makers in Jaipur, India'
                    ]
                }

            ],
            note: [
                `Due to the handcrafted nature of our rugs, slight variations in patterns and colors are inherent to each piece.`
            ]
        },
        {
            heading: 'Sustainably & ethically crafted',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        'Eco-friendly PET yarn made of recycled plastics',
                        SCROLL_LINKS[FAQS_SPECS].SMETA,
                        SCROLL_LINKS[FAQS_SPECS].GOODWEAVE,
                        'GoodWeave label also ensures that your purchase helps support programs to educate children and improve conditions for adult workers in the community',
                        SCROLL_LINKS[FAQS_SPECS].SA8000
                    ]
                }
            ]
        },
        SHARED_CONTENT.RUG_CARE
    ],
    [productCodes.MYRA_BEDROOM_RUG]: [
        {
            heading: 'Features',
            content: [
                {
                    type: 'list',
                    body: [
                        '60% wool, 40% bamboo viscose rayon',
                        '1/2” pile height',
                        'Double-sided, unbacked construction',
                        'Suitable for light to heavy-traffic areas',
                        `Meticulously designed to pair perfectly with Saatva upholstered ${SHARED_LINKS.BEDROOM_FURNITURE}`,
                        'Handcrafted by one of the world’s most prestigious rug makers in Jaipur, India'
                    ]
                }

            ],
            note: [
                `Due to the handcrafted nature of our rugs, slight variations in patterns and colors are inherent to each piece.`
            ]
        },
        {
            heading: 'Sustainably & ethically crafted',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        'Renewable & sustainable natural wool',
                        SCROLL_LINKS[FAQS_SPECS].SMETA,
                        SCROLL_LINKS[FAQS_SPECS].GOODWEAVE,
                        'GoodWeave label also ensures that your purchase helps support programs to educate children and improve conditions for adult workers in the community',
                        SCROLL_LINKS[FAQS_SPECS].OEKO
                    ]
                }
            ]
        },
        SHARED_CONTENT.RUG_CARE
    ],
    [productCodes.NAVI_BEDROOM_RUG]: [
        {
            heading: 'Features',
            content: [
                {
                    type: 'list',
                    body: [
                        '70% wool, 30% polyester"',
                        '1/2” pile height',
                        'Double-sided, unbacked construction',
                        'Suitable for light to heavy-traffic areas',
                        `Meticulously designed to pair perfectly with Saatva upholstered ${SHARED_LINKS.BEDROOM_FURNITURE}`,
                        'Handcrafted by one of the world’s most prestigious rug makers in Jaipur, India'
                    ]
                }

            ],
            note: [
                `Due to the handcrafted nature of our rugs, slight variations in patterns and colors are inherent to each piece.`
            ]
        },
        {
            heading: 'Sustainably & ethically crafted',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        'Renewable & sustainable natural wool',
                        SCROLL_LINKS[FAQS_SPECS].SMETA,
                        SCROLL_LINKS[FAQS_SPECS].GOODWEAVE,
                        'GoodWeave label also ensures that your purchase helps support programs to educate children and improve conditions for adult workers in the community',
                        SCROLL_LINKS[FAQS_SPECS].SA8000
                    ]
                }
            ]
        },
        SHARED_CONTENT.RUG_CARE
    ],
    [productCodes.TERNA_BEDROOM_RUG]: [
        {
            heading: 'Features',
            content: [
                {
                    type: 'list',
                    body: [
                        '80% wool, 20% cotton',
                        '1/2” pile height',
                        '100% cotton backing',
                        'Suitable for light to heavy-traffic areas',
                        `Meticulously designed to pair perfectly with Saatva upholstered ${SHARED_LINKS.BEDROOM_FURNITURE}`,
                        'Handcrafted by one of the world’s most prestigious rug makers in Jaipur, India'
                    ]
                }

            ],
            note: [
                `Due to the handcrafted nature of our rugs, slight variations in patterns and colors are inherent to each piece.`
            ]
        },
        {
            heading: 'Sustainably & ethically crafted',
            content: [
                {
                    type: 'list',
                    hasScrollLink: true,
                    body: [
                        'Renewable & sustainable natural wool',
                        SCROLL_LINKS[FAQS_SPECS].SMETA,
                        SCROLL_LINKS[FAQS_SPECS].GOODWEAVE,
                        'GoodWeave label also ensures that your purchase helps support programs to educate children and improve conditions for adult workers in the community',
                        SCROLL_LINKS[FAQS_SPECS].SA8000
                    ]
                }
            ]
        },
        SHARED_CONTENT.RUG_CARE
    ],
    [productCodes.SANTORINI]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Twin</span> - 44.7\" W x 84.4\" L x 42\" H',
                        '<span class="t-fontWeight--normal">Full</span> - 61\" W x 84.4\" L x 42\" H',
                        '<span class="t-fontWeight--normal">Queen</span> - 66.8\" W x 89.3\" L x 42\" H',
                        '<span class="t-fontWeight--normal">King</span> - 82.7\" W x 89.3\" L x 42\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 78.8\" W x 93.2\" L x 42\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> - 6\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  8\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> - 2.9\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> - 6.1\"',
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.COLOGNE_WOOD]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Made with extra-thick, hand-finished crown cut oak veneers over a solid kiln-dried hardwood and engineered wood frame',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Intensive, multi-step finishing process results in a tactile, matte surface with pronounced textural grain*', 
                    'High-quality finished plywood slats with multiple support feet & vertical center support for added stability', 
                    'Fortified bolts and brackets to keep attachment points stable and durable', 
                    'Finished with the same oak veneers on the back for a complete look that allows the option of a freestanding bed'
                ]
            }],
            note: [
                `*Due to the hand-finished process, variations in the wood grain are to be expected and celebrated. Each bed frame finish is unique and no two are exactly alike.`
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 65.5\" W x 84.6\" L x 60\" H',
                        '<span class="t-fontWeight--normal">King</span> - 81.3\" W x 84.6\" L x 60\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 77.4\" W x 88.6\" L x 60\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> - 5.9\"',
                        '<span class="t-fontWeight--normal">Wooden legs height</span> - 5.9\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> - 1.8\"',
                        '<span class="t-fontWeight--normal">Footboard thickness</span> - 1.8\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> - 1.8\"',
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.VALENCIA]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 66\" W x 89.2\" L x 41.4\" H',
                        '<span class="t-fontWeight--normal">King</span> - 81.9\" W x 89.2\" L x 41.4\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 78\" W x 93.1\" L x 41.4\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> - 7\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> - 7.2\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> - 3.9\"',
                        '<span class="t-fontWeight--normal">Headboard thicknes</span> - 5.1\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.MINORI]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 73.8\" W x 89.4\" L x 53.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 89.8\" W x 89.4\" L x 53.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 85.8\" W x 92.5\" L x 53.7\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> - 2.4\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> - 11.4\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> - 3\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> - 5.8\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_FOUNDATION_HEIGHT_LIMIT,
    ],
    [productCodes.MILAN]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium finished plywood slats for long-lasting support',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Multiple support feet and vertical center support adds stability to the slats',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 65.6\" W x 92.5\" L x 52.2\" H',
                        '<span class="t-fontWeight--normal">King</span> - 81.7\" W x 92.5\" L x 52.2\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 77.8\" W x  96.4\" L x 52.2\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> - 5.9\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  6.9\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> - 3.4\"',
                        '<span class="t-fontWeight--normal">Footboard height</span> - 19.7\"',
                        '<span class="t-fontWeight--normal">Footboard thickness</span> - 5.5\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> - 5.5\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.MERANO]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium finished plywood slats for long-lasting support',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Multiple support feet and vertical center support adds stability to the slats',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 67.3\" W x 94.1\" L x 37.6\" H',
                        '<span class="t-fontWeight--normal">King</span> - 83.5\" W x 94.1\" L x 37.6\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 79.5\" W x 98\" L x 37.6\" H',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  12.4\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> - 2.5\"',
                        '<span class="t-fontWeight--normal">Headboard & footboard thickness</span> - 4.5\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.AMALFI_PLATFORM_BED]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 66.6\" W x 88.2\" L x 12.6\" H',
                        '<span class="t-fontWeight--normal">King</span> - 82.6\" W x 88.2\" L x 12.6\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 78.7\" W x 92.2\" L x 12.6\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11.3\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  3\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.ADRA_LEATHER]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Sustainably sourced premium leathers that only get better with age',
                    'Premium stained plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 77.2\" W x 100.8\" L x 45.6\" H',
                        '<span class="t-fontWeight--normal">King</span> - 93.3\" W x 100.8\" L x 45.6\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 89.4\" W x 104.7\" L x 45.6\" H',
                        '<span class="t-fontWeight--normal">Wooden legs height</span> -  1.6\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  10.8\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  9.7\"',
                        '<span class="t-fontWeight--normal">Footboard thickness</span> -  8.3\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  9.4\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.AMALFI]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 74\" W x 89\" L x 69\" H',
                        '<span class="t-fontWeight--normal">King</span> - 90.2\" W x 89\" L x 69\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 86\" W x 93.1\" L x 69\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> - 11.3\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> - 3\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> - 8.5\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_FOUNDATION_HEIGHT_LIMIT,
    ],
    [productCodes.LYON]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium finished plywood slats for long-lasting support',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Multiple support feet and vertical center support adds stability to the slats',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 69.3\" W x 90\" L x 48\" H',
                        '<span class="t-fontWeight--normal">King</span> - 85.4\" W x 90\" L x  48\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 81.5\" W x 93.9\" L x  48\" H',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  10.8\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  4.1\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  4.3\"',
                        '<span class="t-fontWeight--normal">Headboard clearance</span> -  2\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.COPENHAGEN]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 69.7\" W x 92.4\" L x 38.4\" H',
                        '<span class="t-fontWeight--normal">King</span> - 85.6\" W x 92.4\" L x 38.4\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 81.7\" W x 96.5\" L x 38.4\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  7\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> - 6.9\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> - 4.5\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> - 3.5\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.LYON_WITH_STORAGE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Hydraulic assist mechanism with safety locks for easy lifting and closing with a handle at the foot of the bed',
                    'Ample storage space, perfect for extra bedding, pillows & seasonal clothing',
                    'Storage cavity with vertical divider and base platforms keep your things organized and off the floor',
                    'Solid platform support base lifts up vertically, perfect for smaller spaces',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions (Exterior)',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 69.3\" W x 91.5\" L x 48\" H',
                        '<span class="t-fontWeight--normal">King</span> - 85.2\" W x 91.5\" L x 48\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 81.3\" W x 95.5\" L x 48\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  2\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  10.8\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  4.1\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  5.3\"'
                    ]
                }
            ],
        },
        {
            heading: 'Dimensions (Interior storage)*',
            combined: true,
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 57.3\" W x 83.5\" L x 7.1\" H',
                        '<span class="t-fontWeight--normal">King</span> - 73.3\" W x 83.5\" L x 7.1\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 69.3\" W x 87.4\" L x 7.1\" H'
                    ]
                }
            ],
            disclaimer: [
                '* Storage cavity has a vertical center divider'
            ]
        },
        SHARED_CONTENT.BED_FRAME_INCOMPATIBLE_WITH_ADJUSTABLE_BASES,
    ],
    [productCodes.CASSIS]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium finished plywood slats for long-lasting support',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Multiple support feet and vertical center support adds stability to the slats',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 71.3\" W x 93.9\" L x 42.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 87.4\" W x 93.9\" L x  42.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 83.3\" W x 97.4\" L x  42.7\" H',
                        '<span class="t-fontWeight--normal">Wooden base height</span> -  1.1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  12\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  5\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  20.2\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.SANTORINI_PLATFORM_BED]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Twin</span> - 44.7\" W x 83.1\" L x 14\" H',
                        '<span class="t-fontWeight--normal">Full</span> - 61\" W x 83.1\" L x 14\" H',
                        '<span class="t-fontWeight--normal">Queen</span> - 66\" W x 88\" L x 14\" H',
                        '<span class="t-fontWeight--normal">King</span> - 82\" W x 88\" L x 14\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 78\" W x 92\" L x 14\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  6\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  8\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  2.9\"',
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.SIENA_LEATHER]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Sustainably sourced premium leathers that only get better with age',
                    'Premium stained wood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 65\" W x 88.8\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">King</span> - 81\" W x 88.8\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 77.4\" W x 94\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  4.75\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  8.25\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  2\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  5.5\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.CALAIS]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium finished plywood slats for long-lasting support',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Multiple support feet and vertical center support adds stability to the slats',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 64.4\" W x 87.3\" L x 58.1\" H',
                        '<span class="t-fontWeight--normal">King</span> - 80.6\" W x 87.3\" L x 58.1\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 76.6\" W x  91.2\" L x 58.1\" H',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  2\"',
                        '<span class="t-fontWeight--normal">Footboard height</span> -  29.9\"',
                        '<span class="t-fontWeight--normal">Footboard thickness</span> -  2.7\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  3.7\"',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  5.7\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.CRETE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium finished plywood slats for long-lasting support',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Multiple support feet and vertical center support adds stability to the slats',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 78.4\" W x 99.8\" L x 40\" H',
                        '<span class="t-fontWeight--normal">King</span> - 94.5\" W x 99.8\" L x 40\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 90.5\" W x 103.7\" L x 40\" H',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  10.2\"',
                        '<span class="t-fontWeight--normal">Side rails & footboard thickness</span> -  8.7\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  9.8\"',
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.PORTO]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium finished plywood slats for long-lasting support',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 71.7\" W x 91.5\" L x 46.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 87.9\" W x 91.5\" L x 46.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 84\" W x 95.4\" L x 46.7\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  7.1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  6.4\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  5.2\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  5.3\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.SYDNEY]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed',
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 65\" W x 88\" L x 44\" H',
                        '<span class="t-fontWeight--normal">King</span> - 81\" W x 88\" L x 44\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 87\" W x 92\" L x 44\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  8.5\"',
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_INCOMPATIBLE_WITH_ADJUSTABLE_BASES,
    ],
    [productCodes.HALLE_STORAGE_PLATFORM_BED]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Hydraulic assist mechanism with safety locks for easy lifting and closing with a handle at the foot of the bed',
                    'Ample storage space, perfect for extra bedding, pillows & seasonal clothing',
                    'Storage cavity with vertical divider and base platforms keep your things organized and off the floor',
                    'Solid platform support base lifts up vertically, perfect for smaller spaces',
                    'Fortified bolts and brackets to keep attachment points stable and durable'
                ]
            }]
        },
        {
            heading: 'Dimensions (Exterior)',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 65\" W x 87\" L x 12.6\" H',
                        '<span class="t-fontWeight--normal">King</span> - 81\" W x 87\" L x 12.6\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 77\" W x 91\" L x 12.6\" H',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11.6\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  2.2\"',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  1\"'
                    ]
                }
            ],
        },
        {
            heading: 'Dimensions (Interior storage)*',
            combined: true,
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 57.3\" W x 82.2\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 72.8\" W x 82.2\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 68.9\" W x 86.3\" L x 7.7\" H'
                    ]
                }
            ],
            disclaimer: [
                '* Storage cavity has a vertical center divider'
            ]
        },
        SHARED_CONTENT.BED_FRAME_INCOMPATIBLE_WITH_ADJUSTABLE_BASES,
    ],
    [productCodes.MARSEILLE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 65.9\" W x 93.5\" L x 72.4\" H',
                        '<span class="t-fontWeight--normal">King</span> - 82\" W x 93.5\" L x 72.4\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 78.1\" W x 97.4\" L x 72.4\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  4.1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11.8\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  2.5\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  5.5\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_FOUNDATION_HEIGHT_LIMIT,
    ],
    [productCodes.MARBELLA]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed',
                    'Optional side panels (sold separately) attach to the headboard for dramatic look'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - No side panels: 68.1\" W x 90.2\" L x 71.7\" H; <br class=\'furnitureOverview__hidden--md-only\'>Side panels: 129.1\" W x 90.2\" L x 71.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - No side panels: 84.1\" W x 90.2\" L x 71.7\" H; <br class=\'furnitureOverview__hidden--md-only\'>Side panels: 145.1\" W x 90.2\" L x 71.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - No side panels: 79.9\" W x 94.3\" L x 71.7\" H; <br class=\'furnitureOverview__hidden--md-only\'>Side panels: 140.9\" W x 94.3\" L x 71.7\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  2.9\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  10.4\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  3.5\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  6.1\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_FOUNDATION_HEIGHT_LIMIT,
    ],
    [productCodes.MINORI_WITH_STORAGE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Hydraulic assist mechanism with safety locks for easy lifting and closing with a handle at the foot of the bed',
                    'Ample storage space, perfect for extra bedding, pillows & seasonal clothing',
                    'Storage cavity with vertical divider and base platforms keep your things organized and off the floor',
                    'Solid platform support base lifts up vertically, perfect for smaller spaces',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions (Exterior)',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 73.2\" W x 89.6\" L x 53.5\" H',
                        '<span class="t-fontWeight--normal">King</span> - 89.4\" W x 89.6\" L x 53.5\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 85.2\" W x 94.3\" L x 53.5\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11.6\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  3.1\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  5.9\"'
                    ]
                }
            ],
        },
        {
            heading: 'Dimensions (Interior storage)*',
            combined: true,
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 57\" W x 81\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 72.9\" W x 81\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 69\" W x 85\" L x 7.7\" H'
                    ]
                }
            ],
            disclaimer: [
                '* Storage cavity has a vertical center divider'
            ]
        },
        SHARED_CONTENT.BED_FRAME_INCOMPATIBLE_WITH_ADJUSTABLE_BASES,
    ],
    [productCodes.AMALFI_WITH_STORAGE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Hydraulic assist mechanism with safety locks for easy lifting and closing with a handle at the foot of the bed',
                    'Ample storage space, perfect for extra bedding, pillows & seasonal clothing',
                    'Storage cavity with vertical divider and base platforms keep your things organized and off the floor',
                    'Solid platform support base lifts up vertically, perfect for smaller spaces',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions (Exterior)',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 74\" W x 89.4\" L x 68.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 90.2\" W x 89.4\" L x 68.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 86.2\" W x 93.2\" L x 68.7\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11.6\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  3.1\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  8.5\"'
                    ]
                }
            ],
        },
        {
            heading: 'Dimensions (Interior storage)*',
            combined: true,
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 57\" W x 81\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 72.8\" W x 81\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 69\" W x 85\" L x 7.7\" H'
                    ]
                }
            ],
            disclaimer: [
                '* Storage cavity has a vertical center divider'
            ]
        },
        SHARED_CONTENT.BED_FRAME_INCOMPATIBLE_WITH_ADJUSTABLE_BASES,
    ],
    [productCodes.CASSIS_WITH_STORAGE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Hydraulic assist mechanism with safety locks for easy lifting and closing with a handle at the foot of the bed',
                    'Ample storage space, perfect for extra bedding, pillows & seasonal clothing',
                    'Storage cavity with vertical divider and base platforms keep your things organized and off the floor',
                    'Solid platform support base lifts up vertically, perfect for smaller spaces',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions (Exterior)',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 71\" W x 94.3\" L x 42.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 87\" W x 94.3\" L x 42.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 83\" W x 98.3\" L x 42.7\" H',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11.9\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  4.9\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  19.5\"'
                    ]
                }
            ],
        },
        {
            heading: 'Dimensions (Interior storage)*',
            combined: true,
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 57\" W x 70.5\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 72.9\" W x 70.5\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 69\" W x 74.5\" L x 7.7\" H'
                    ]
                }
            ],
            disclaimer: [
                '* Storage cavity has a vertical center divider'
            ]
        },
        SHARED_CONTENT.BED_FRAME_INCOMPATIBLE_WITH_ADJUSTABLE_BASES,
    ],
    [productCodes.HALLE_WITH_STORAGE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Hydraulic assist mechanism with safety locks for easy lifting and closing with a handle at the foot of the bed',
                    'Ample storage space, perfect for extra bedding, pillows & seasonal clothing',
                    'Storage cavity with vertical divider and base platforms keep your things organized and off the floor',
                    'Solid platform support base lifts up vertically, perfect for smaller spaces',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions (Exterior)',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 65\" W x 87\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">King</span> - 81\" W x 87\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 77\" W x 91\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11.6\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  2.2\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  3.5\"'
                    ]
                }
            ],
        },
        {
            heading: 'Dimensions (Interior storage)*',
            combined: true,
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 57.3\" W x 82.2\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">King</span> - 72.8\" W x 82.2\" L x 7.7\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 68.9\" W x 86.3\" L x 7.7\" H'
                    ]
                }
            ],
            disclaimer: [
                '* Storage cavity has a vertical center divider'
            ]
        },
        SHARED_CONTENT.BED_FRAME_INCOMPATIBLE_WITH_ADJUSTABLE_BASES,
    ],
    [productCodes.LUCERNE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 73.6\" W x 90\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">King</span> - 89.8\" W x 90\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 85.9\" W x 93.6\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  10.4\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  3.3\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  6.3\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.HALLE]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium plywood slats with multiple support feet & vertical center support for added stability',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Queen</span> - 69\" W x 89\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">King</span> - 85\" W x 89\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 81\" W x 93\" L x 48.3\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  1\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  11.6\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  4\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  4.25\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],
    [productCodes.RHODES]: [
        {
            heading: 'Features & Benefits',
            content: [{
                type: 'list',
                body: [
                    'Frame made with sustainably sourced hardwood',
                    'Kiln-dried hardwood for extra durability, never twists or warps',
                    'Premium finished plywood slats for long-lasting support',
                    'Fortified bolts and brackets to keep attachment points stable and durable',
                    'Multiple support feet and vertical center support adds stability to the slats',
                    'Fully upholstered back allows the option of a freestanding bed'
                ]
            }]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Twin</span> - 44.9\" W x 83.4\" L x 46\" H',
                        '<span class="t-fontWeight--normal">Full</span> - 61\" W x 83.4\" L x 46\" H',
                        '<span class="t-fontWeight--normal">Queen</span> - 66.6\" W x 89.3\" L x 46\" H',
                        '<span class="t-fontWeight--normal">King</span> - 82.9\" W x 89.3\" L x 46\" H',
                        '<span class="t-fontWeight--normal">Cal King</span> - 78.8\" W x 92.9\" L x 46\" H',
                        '<span class="t-fontWeight--normal">Underbed clearance</span> -  6\"',
                        '<span class="t-fontWeight--normal">Side rails height</span> -  7.8\"',
                        '<span class="t-fontWeight--normal">Side rails thickness</span> -  2.8\"',
                        '<span class="t-fontWeight--normal">Headboard thickness</span> -  6\"'
                    ]
                }
            ],
        },
        SHARED_CONTENT.BED_FRAME_COMPATIBLE_WITH_DUAL_SIDED,
    ],

    // Chairs
    [productCodes.ANAIS_CHAIR]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to pair with Saatva bed frames & bed benches',
                        'Extra-wide, deep sink-in seat for plush comfort',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainably sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Individually handcrafted and upholstered by a skilled craftsperson',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 28\" L x 32\" W x 34.3\"H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 18\"',
                        '<span class="t-fontWeight--normal">Seat depth</span> - 22\"',
                        '<span class="t-fontWeight--normal">Seat width</span> - 21.7\"',
                        '<span class="t-fontWeight--normal">Wood base height</br>(on non-swivel option)</span> - 2\"'
                    ]
                }
            ]
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Graceful high-crowned back with an open armless silhouette',
                                'Waterfall tailoring creates a sleek, streamlined look',
                                'Hidden swivel mechanism creates a clean, tailored look (on swivel option)'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.ANAIS_CHAIR}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.COMO_SWIVEL_CHAIR]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to pair with Saatva bed frames & bed benches',
                        'Extra-wide, deep sink-in seat for plush comfort',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainable sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Individually handcrafted and upholstered by a skilled craftsperson',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 30.5\" L x 32.5\" W x 28.7\" H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 18\"',
                        '<span class="t-fontWeight--normal">Seat depth</span> - 22.8\"',
                        '<span class="t-fontWeight--normal">Seat width</span> - 22.6\"',
                        '<span class="t-fontWeight--normal">Full arm height</span> - 23.6\"'
                    ]
                }
            ],
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Geometric shelter arm creates a deeply enveloping, supportive seat',
                                'Waterfall tailoring creates a sleek, streamlined look',
                                'Hidden swivel mechanism creates a clean, tailored look'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.ANAIS_CHAIR}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.LOGAN_CHAIR]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to pair with Saatva bed frames & bed benches',
                        'Extra-wide, firm seating gradually breaks in over time',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainably sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Individually handcrafted and upholstered by a skilled craftsperson',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 30\"L x 36\" W x  31\"H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 18.7\"',
                        '<span class="t-fontWeight--normal">Seat depth</span> - 23.2\"',
                        '<span class="t-fontWeight--normal">Seat width</span> - 22.4\"',
                        '<span class="t-fontWeight--normal">Full arm height</span> - 24\"'
                    ]
                }
            ],
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Overfilled cushions & slightly padded arms',
                                'Slightly tapered wooden legs with a deep espresso finish'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.ANAIS_CHAIR}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.LUNA_SWIVEL_CHAIR]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to pair with Saatva bed frames & bed benches',
                        'Extra-wide, deep sink-in seat for plush comfort',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainably sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Individually handcrafted and upholstered by a skilled craftsperson',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 31.7\"L x 32\" W x  28.5\"H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 18.7\"',
                        '<span class="t-fontWeight--normal">Seat depth</span> - 22\"',
                        '<span class="t-fontWeight--normal">Full arm height</span> - 25.6\"'
                    ]
                }
            ],
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Soft curves and compact rounded shape allows for flexible placement in any space',
                                'Hidden swivel mechanism creates a clean, tailored look'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.ANAIS_CHAIR}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.CONSTANCE_TUFTED_OTTOMAN]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to fit at the foot of both queen & king-size beds',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainably sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Deep button tufting on every side',
                        'Polyfill padding throughout',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 52\" L x 22\" W x 18\" H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 18\"'
                    ]
                }
            ],
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Deep button tufting creates a decadent plushness'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.CONSTANCE_TUFTED_OTTOMAN}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.ARNO_CUSHIONED_BENCH]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to fit at the foot of both queen & king-size beds',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainably sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Polyfill seat cushion',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 48\" L x 18\" W x 18.5\" H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 18.5\"',
                        '<span class="t-fontWeight--normal">Leg height</span> - 10\"'
                    ]
                }
            ],
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Sleek black steel legs add an elevated look'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.ARNO_CUSHIONED_BENCH}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.BRIENNE_CHANNEL_OTTOMAN]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to fit at the foot of both queen & king-size beds',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainably sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Polyfill padding throughout',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 54\" L x 20\" W x 18\" H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 18\"'
                    ]
                }
            ],
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Padded channel tufting creates a decadent plushness'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.BRIENNE_CHANNEL_OTTOMAN}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.RHONE_STORAGE_BENCH]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to fit at the foot of both queen & king-size beds',
                        'Reinforced, high-quality hinge hardware',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainably sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Tufted seat cushion',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 55\" L x 20\" W x 20\" H',
                        '<span class="t-fontWeight--normal">Interior storage</span> - 52\" L x 17\" W x 12.5\" H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 20\"'
                    ]
                }
            ],
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Comfy cushioned seat is tufted for a more refined look',
                                'Hand-finished nailheads along the bottom add an elegant touch: Natural Linen with antique brass and Graphite & Taupe Velvet with chrome nailhead finish'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.RHONE_STORAGE_BENCH}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.LENA_CUSHIONED_BENCH]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Designed to fit at the foot of both queen & king-size beds',
                        'Upholstered in high-quality fabrics with no chemical additives',
                        'Made with sustainably sourced hardwood',
                        'Kiln-dried hardwood for extra durability',
                        'Plush, high-resiliency foam cushion',
                        'Adheres to the Toxic Substances Control Act (TSCA), contains no VOCs or other harmful substances'
                    ]
                }
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Exterior</span> - 52\"L x 20\" W x  16.5\"H',
                        '<span class="t-fontWeight--normal">Seat height</span> - 14.5\"',
                        '<span class="t-fontWeight--normal">Leg height</span> - 2\"'
                    ]
                }
            ],
        },
        {
            heading: 'Fabric & Finish Details',
            content: [
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            items: [
                                'Sleek, low-profile track arms add a modern silhouette'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Vintage Velvet',
                            items: [
                                'Irresistibly plush with a rich texture and a subtle matte sheen',
                                'Made from high-quality poly-cotton blend for maximum stain resistance and maximum durability'
                            ]
                        }
                    ]

                },
                {
                    type: 'list-typed',
                    combined: true,
                    noMarginLeft: true,
                    body: [
                        {
                            title: 'Linen',
                            items: [
                                'Elegant and eco-friendly',
                                'Made from natural flax fibers without any harmful chemicals or dyes, the fabric is highly durable with a finely textured finish'
                            ]
                        }
                    ],

                }
            ],
            nodeElement: [
                (
                    <RequestSwatches
                        key={`${productCodes.LENA_CUSHIONED_BENCH}-request-swatches`}
                        className='u-marginTop--lg'
                        isLink={true}
                        modalTitle='Request fabric samples'>Request Swatches</RequestSwatches>
                )
            ]
        }
    ],
    [productCodes.ADJUSTABLE_BASE]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Elevates head and feet for customized comfort',
                        'Easy-to-use wireless remote control',
                        'Can also be operated via the Leggett & Platt Adjustable Bed Control app (only on the App Store for iPhone and iPad)',
                        'Quiet and discreet motor is housed away from sight to keep you peaceful and clutter free',
                        'Built-in remote flashlight lights your path at night',
                        'Zero-clearance design allows the base to be used without legs on platform bases',
                        'Slim profile virtually disappears underneath the mattress',
                    ]
                }
            ],
            disclaimer: [
                '* Note: Saatva Adjustable Base is final sale. No returns or exchanges.'
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Twin XL</span> - 38" W x 80" L',
                        '<span class="t-fontWeight--normal">Full</span> - 54" W x 75" L',
                        '<span class="t-fontWeight--normal">Queen</span> - 60" W x 80" L',
                        '<span class="t-fontWeight--normal">King / Split King</span> - 76" W x 80" L',
                        'Adjustable legs go up in half-inch increments for bed height options of 12" to 17"',
                    ]
                }
            ],
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'prefixed-heading',
                    customSvg: CHECKMARK_SVG,
                    body: [
                        {
                            title: 'Bed Frames',
                            body: ADJUSTABLE_BASE_COMPATIBILITY.BED_FRAMES,
                        },
                        {
                            title: 'Mattresses',
                            body: ADJUSTABLE_BASE_COMPATIBILITY.MATTRESSES,
                        }
                    ]
                },
            ],
        },
    ],
    [productCodes.ADJUSTABLE_BASE_PLUS]: [
        {
            heading: 'Features & Benefits',
            content: [
                {
                    type: 'list',
                    body: [
                        'Elevates head and feet for customized comfort',
                        'Easy-to-use wireless remote control',
                        'Can also be operated via the Leggett & Platt Adjustable Bed Control app (only on the App Store for iPhone and iPad)',
                        'Quiet and discreet motor is housed away from sight to keep you peaceful and clutter free',
                        'Zero-gravity preset instantly adjusts for perfectly balanced, weightless support at the touch of a button',
                        'Anti-snore preset elevates the head 7° to open up airways and reduce snoring',
                        'Full-body wave, head & leg massage with three speed options',
                        'Under-bed lighting plus a built-in remote flashlight lights your path at night',
                        'Zero-clearance design allows the base to be used without legs on platform bases',
                        'USB-A & USB-C ports underneath the frame on each side for convenient bedside charging',
                    ]
                }
            ],
            disclaimer: [
                '* Adjustable bases are lifestyle products and are NOT eligible for returns or exchanges.'
            ]
        },
        {
            heading: 'Dimensions',
            content: [
                {
                    type: 'list',
                    body: [
                        '<span class="t-fontWeight--normal">Twin</span> - 38" W x 75" L',
                        '<span class="t-fontWeight--normal">Twin XL</span> - 38" W x 80" L',
                        '<span class="t-fontWeight--normal">Full</span> - 54" W x 75" L',
                        '<span class="t-fontWeight--normal">Queen</span> - 60" W x 80" L',
                        '<span class="t-fontWeight--normal">King / Split King</span> - 76" W x 80" L',
                        '<span class="t-fontWeight--normal">Cal King / Split Cal King</span> - 72" W x 84" L',
                        'Adjustable legs go up in half-inch increments for bed height options of 12" to 17"',
                    ]
                }
            ],
        },
        {
            heading: 'Compatibility',
            content: [
                {
                    type: 'prefixed-heading',
                    customSvg: CHECKMARK_SVG,
                    body: [
                        {
                            title: 'Bed Frames',
                            body: ADJUSTABLE_BASE_COMPATIBILITY.BED_FRAMES,
                        },
                        {
                            title: 'Mattresses',
                            body: ADJUSTABLE_BASE_COMPATIBILITY.MATTRESSES,
                        }
                    ]
                },
            ],
        },
    ],
}

export default config
