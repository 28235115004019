import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { AccordionSet } from '@saatva-bits/pattern-library.components.accordion-set'
import { Accordion } from '@saatva-bits/pattern-library.components.accordion'
import productOverviewConfig from './ProductOverviewConfig'
import useScrollToFaqsAndSpecs from '@/hooks/useScrollToFaqsAndSpecs'
import useDeviceType from '@/hooks/useDeviceType'
import { MEMORY_FOAM_HYBRID } from '@/constants/product-codes'
import { CONTRAST1, CONTRAST2 } from './ProductOverviewThemes'
import styles from './ProductOverview.module.scss'

const ProductOverview = ({ productCode, numColumns = 4, className, theme }) => {
    const { ScrollToFaqsAndSpecsButton } = useScrollToFaqsAndSpecs()
    const { isDesktop } = useDeviceType('desktop')

    const currentOverviewData = productOverviewConfig[productCode]
    if (!currentOverviewData) return null

    const noteWrapperClasses = classNames('u-paddingTop--xs', styles.note, {
        [styles.contrast1]: theme === CONTRAST1
    })

    const listItemClasses = classNames(styles.listItem, {
        'u-displayBlock': productCode === MEMORY_FOAM_HYBRID
    })
    /**
     *
     * @param {{
     *      heading: string;
     *      content: { type: string; hasScrollLink: boolean?; body: any[]; }[];
     *      intro?: string;
     *      disclaimer?: string[];
     * }} item
     * @param {(string | function)[]} items - This is actually the body property, has a lot of possible types too
     * @param {string?} disclaimer
     * @param {string?} note
     * @param {string} heading
    */
    const renderListContent = (item, items, disclaimer, note, heading) => {
        return (
            <>
                <ul className={styles.list}>
                    {items && items.map((feature, i) => {
                        let content = null

                        if (item.hasScrollLink && typeof feature === 'function') {
                            content = <>{feature(ScrollToFaqsAndSpecsButton)}</>
                        } else if (item.hasTooltip && typeof feature === 'object'){
                            content = <>{feature}</>
                        } else {
                            content = <span dangerouslySetInnerHTML={{ __html: feature }}></span>
                        }

                        return (
                            <React.Fragment key={`feature-${heading}-${i}`}>
                                <li className={listItemClasses}>
                                    {content}
                                </li>
                                {feature.disclaimer && renderDisclaimer(feature.disclaimer)}
                            </React.Fragment>
                        )
                    })}
                </ul>
                {disclaimer && renderDisclaimer(disclaimer)}
                {
                    note &&
                    <div className={noteWrapperClasses}>
                        {note.map((item, index) => {
                            return <span className="u-displayBlock" dangerouslySetInnerHTML={{ __html: item }} key={`overview-note-${heading}-${index}`}></span>
                        })}
                    </div>
                }
            </>
        )
    }

    const renderDisclaimer = (disclaimer, customSvg) => {
        return (
            <div className={`t-bodySm u-paddingTop--xs ${customSvg && 'u-paddingLeft--lg'}`}>
                {disclaimer.map((item, index) => {
                    return <span className="t-italic u-displayBlock" dangerouslySetInnerHTML={{ __html: item }} key={`overview-disclaimer-${index}`}></span>
                })}
            </div>
        )
    }

    const renderNodeElement = (node) => {
        return (
            <div className={`t-bodySm u-paddingTop--xs`}>
                {node}
            </div>
        )
    }

    const renderTableTypedContent = (body) => {
        return body.map((item, index) => {
            return (
                <div className={`${styles['list-table-typed-item']} u-paddingHorizontal--3xs`} key={`list-table-typed-${index}`}>
                    <span className="u-displayBlock t-base">{item.cellHead}</span>
                    <span className="u-displayBlock">{item.cellBody}</span>
                </div>
            )
        })
    }

    const renderHeadingTypedContent = (body, CustomSvg) => {
        return body.map((item, index) => {
            const titleClass = classNames(item.className, 'u-displayBlock t-base')
            return (
                <div className={`${styles['list-heading-typed-item']} u-paddingHorizontal--3xs u-paddingBottom--sm`} key={`list-heading-typed-${index}`}>
                    {CustomSvg && item.customSvg !== false && <div>{CustomSvg}</div>}
                    <div>
                        <span className={titleClass}>{item.title}</span>
                        { typeof item.body === 'string' ? (
                            <span className="u-displayBlock" dangerouslySetInnerHTML={{ __html: item.body }}></span>
                        ) : (
                            <span className='u-displayBlock'>{item.body}</span>
                        )}
                    </div>
                </div>
            )
        })
    }

    const renderPrefixedHeadingTypedContent = (body, customSvg) => {
        return body.map((item, index) => {
            const titleClass = classNames(item.className, 'u-displayBlock t-base')
            return (
                <div className={`${styles.listPrefixedHeadingTypedItem} u-paddingHorizontal--3xs u-paddingBottom--sm`} key={`list-prefixed-heading-typed-${index}`}>
                    <div>
                        <span className={titleClass}>{item.title}</span>
                        { typeof item.body === 'string' ? (
                            <span className="u-displayBlock" dangerouslySetInnerHTML={{ __html: item.body }}></span>
                        ) : (
                            <span className={styles.flexWrapper}>{customSvg}{item.body}</span>
                        )}
                    </div>
                </div>
            )
        })
    }

    const renderListTypedContent = (body, heading, noMarginLeft, disclaimer) => {
        const listTypedContentClasses = !noMarginLeft ? classNames('col u-fullWidth col--xs-12'): ''
        return body.map((item, index) => {
            const spanClasses = classNames('u-displayBlock t-base', {
                'u-paddingTop--md': index !== 0
            })
            return (
                <div key={`list-typed-${heading}-${index}`} className={listTypedContentClasses}>
                    <span className={spanClasses}>{item.title}</span>
                    {renderListContent(body, item.items, disclaimer)}
                </div>
            )
        })
    }

    const selectRenderingColumns = (item, index, disclaimer, note, combined) => {
        switch (item.type) {
            case 'list-typed': {
                return (
                    <div className={`${combined || item.combined ? '' : 'row'}`}>
                        {renderListTypedContent(item.body, item.heading, item.noMarginLeft, disclaimer)}
                    </div>
                )
            }
            case 'table': {
                return (
                    <div className={`${styles.list-table-typed} row u-marginBottom--xs`} key={`overview-table-${index}`}>
                        {renderTableTypedContent(item.body, item.heading)}
                    </div>
                )
            }
            case 'heading': {
                return (
                    <div className={`${styles['list-heading-typed']} u-marginBottom--xs`} key={`overview-heading-${index}`}>
                        {renderHeadingTypedContent(item.body, item.customSvg)}
                        {disclaimer && renderDisclaimer(disclaimer, item.customSvg)}
                    </div>
                )
            }
            case 'prefixed-heading': {
                return (
                    <div className={`${styles['list-prefixed-typed']} u-marginBottom--xs`} key={`overview-prefixed-heading-${index}`}>
                        {renderPrefixedHeadingTypedContent(item.body, item.customSvg)}
                        {disclaimer && renderDisclaimer(disclaimer)}
                    </div>
                )
            }
            case 'list':
            default: {
                return renderListContent(item, item.body, disclaimer, note, item.heading)
            }
        }
    }

    const selectOverviewType = (listItem, combined) => {
        return listItem.content.map((item, index) => {
            return selectRenderingColumns(item, index, listItem.disclaimer, listItem.note, combined)
        })
    }

    const accordionData = currentOverviewData.map((item) => {
        // The ID in this data structure is used as a suffix to customize the accordions IDs.
        // This is then leveraged in the `expandAccordionByIdSuffix` utility.
        // ie: `accordion-title-<id>` and `accordion-content-<id>`
        const idSuffix = item.heading.split(' ')[0].toLowerCase()
        return {
            id: idSuffix,
            title: item.heading,
            content: <>
                {item.intro && <div>{item.intro}</div>}
                {selectOverviewType(item)}
                {item.nodeElement && renderNodeElement(item.nodeElement)}
            </>
        }
    })

    const columnClasses = classNames(styles.columns, `col col--sm-${numColumns}`)
    const iterateElement = () => {
        let desktopElements = []
        let combined = false
        currentOverviewData.forEach((item, index) => {
            if (item.combined) { // If two objects share a column and it is not the last column
                combined = true
            }

            if (combined !== -1) { // Once the last object is render below the last column, it doesn't render the last obj again
                desktopElements.push(
                    <div className={`${columnClasses}`} key={`overview-dynamic-${index}`} >
                        {item.heading && <h3 className={`${styles.header} t-heading2-refined u-marginBottom--md`}>{item.heading}</h3>}
                        {item.intro && <div>{item.intro}</div>}
                        {selectOverviewType(item, combined)}
                        {combined
                            ? <>
                                {currentOverviewData[index + 1].heading && <h3 className={`${styles.header} t-heading2-refined u-marginVertical--md`}>{currentOverviewData[index + 1].heading}</h3>}
                                {currentOverviewData[index + 1].intro && <div>{currentOverviewData[index + 1].intro}</div>}
                                {selectOverviewType(currentOverviewData[index + 1])}
                            </>
                            : <></>
                        }
                        {item.nodeElement && renderNodeElement(item.nodeElement)}
                    </div>
                )
            } else {
                combined = false
            }
            if (combined) {
                combined = -1
            }
        })
        return desktopElements
    }

    const wrapperClasses = classNames(styles.wrapper, className, `section`, {
        [styles.contrast1]: theme === CONTRAST1,
        [styles.contrast2]: theme === CONTRAST2
    })
    const accordionSetClasses = classNames(styles.accordionSet, `containerCol--xs-12`)
    return (
        <div id="product-overview" className={wrapperClasses}>
            <div className="container">
                { isDesktop ? (
                    <div className={`row`}>
                        {iterateElement()}
                    </div>
                ) : (
                    <AccordionSet accordionData={accordionData} className={accordionSetClasses} >
                        <Accordion
                            className={styles.accordion}
                            titleClassName="t-heading3 t-color t-family--serif u-marginBottom--none"
                            titleContainerClassName={styles.accordionTitleContainer}
                            contentClassName={styles.accordionContent}
                            contentContainerClassName={styles.accordionContentContainer}
                        />
                    </AccordionSet>
                )}
            </div>
        </div>
    )
}

ProductOverview.propTypes = {
    productCode: PropTypes.string.isRequired,
    numColumns: PropTypes.number, // this is the width of each column, not the number of columns in the content file
    className: PropTypes.string,
    /** Changing the background color of the section means that other coloring, such as the disclaimer background, needs to change */
    theme: PropTypes.oneOf([CONTRAST1, CONTRAST2])
}

export default React.memo(ProductOverview)
